import React from "react"
import styled from "styled-components"
import ImgAwardGoogle from "../img/award-google"
import ImgAwardMWA from "../img/award-mwa"
import ImgAwardHubspot from "../img/award-hubspot"
import ImgFeaturedOnET from "../img/featuredon-elegantthemes"

const Component = styled.ul`  
    padding: 5em 0;
    width: 50%;
    margin: auto;
    @media screen and (min-width: 691px) {
        width: 90%;
        max-width: 1000px;
        #home-awards {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 50px;
            align-items: start;
        }
    }
    .awards {
        width: 100%;
        max-width: 300px;
        margin: 2em auto;
        padding: 0;
        text-align: center;
    }
    #home-featuredon {
        margin-top: 3em;
    }
`

const Awards = () => (
    <Component>
        <div id="home-awards">
            <div className="awards">
                <ImgAwardGoogle/>
                <p><strong>Digital Marketing Certified</strong><br/>Google × IAB Europe</p>
            </div>
            <div className="awards">
                <ImgAwardMWA/>
                <p><strong>2x Site of the Month Winner</strong><br/>Malaysia Website Awards</p>
            </div>
            <div className="awards">
                <ImgAwardHubspot/>
                <p><strong>Growth-Driven Design Certified</strong><br/>HubSpot Academy</p>
            </div>
        </div>
        <div id="home-featuredon">
            <div className="awards">      
                <h3>Featured on</h3>
                <ImgFeaturedOnET/>
                <p><strong>Divi Design Showcase</strong><br/>Elegant Themes</p>
            </div>
        </div>
    </Component>
)

export default Awards